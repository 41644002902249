import React from 'react'
import { Card, Item } from 'semantic-ui-react'
import ProjectSummary from 'Components/Projects/ProjectSummary'
const ProjectCard = (props) => {

  // Specifies the color of the top bar
  const headerClass = "header-" + props.headerColor;
  // Specifies the text of the top bar
  const headerText = props.headerText;
  
  return (    
    <Card fluid className={headerClass}>
      <Card.Content className='card-header' header={headerText} />
      <Card.Content>
        <Item.Group>
          <ProjectSummary />
        </Item.Group>
      </Card.Content>
    </Card>    
  )

}

export default ProjectCard
