import React, { useState } from 'react'
import { Button, Icon, Modal, Form } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const ChapterAddModal = () => {
  const [open, setOpen] = React.useState(false)
  
  const [chapterCountable, setChapterCountable] = useState('chapter');
  const handleChange = (e, { value }) => {
    setChapterCountable(value);
  }
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Link><Icon name='plus' />Start a new chapter</Link>}
    >
      <Modal.Header>Add a Chapter</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Chapter Title</label>
            <input placeholder='Chapter Title' />
          </Form.Field>
          <Form.Group>
            <Form.Radio
              label='This Is A Chapter And Counts Towards My Word-Count Goal.'
              value='chapter'
              checked={chapterCountable === 'chapter'}
              onChange={handleChange}
            />
            <Form.Radio
              label='This Is A Note And Just Counts For Practice.'
              value='note'
              checked={chapterCountable === 'note'}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button type='submit'>Save Chapter</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ChapterAddModal