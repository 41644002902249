import React from 'react'
import { Button, Item } from 'semantic-ui-react'

const DareMachine = (props) => {
  
  return (
    <Item>
      <Item.Image size='tiny' src='/images/ui/dare-machine-gears.png' alt='' />
      <Item.Content verticalAlign='middle'>
        <Item.Header as='a'>Dare Machine</Item.Header>
        <Item.Description>
          Give your characters nicknames.
        </Item.Description>
        <Item.Extra><Button>Show me another</Button></Item.Extra>
      </Item.Content>
    </Item>
  )

}

export default DareMachine
