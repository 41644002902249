import React from 'react'
import { Image } from 'semantic-ui-react'

const TalkingMonster = () => {

  /* --------BEGIN STYLES--------*/
  // The styling of the cover image. Note that opacity is set to 90% to allow the background color to show through slightly.
  const imgStyle = {
    width: '75px',
    height: '100px',
    opacity: '0.9',
  }  
  // The styling of the cover image plus book background
  const backgroundStyle = {
    backgroundImage: 'url("/images/ui/project-covers/project-cover-blue.png")',
    float: 'left',
    margin: '20px',
    transform: 'rotate(-15deg)',
    backgroundSize: '86px 112px',
    backgroundRepeat: 'no-repeat',
    paddingLeft: '6px',
    paddingTop: '3px',
    width: '86px',
    height: '112px',
  }
  /* --------END STYLES--------*/
  
  return (    
    <div style={backgroundStyle}>
      <Image src='/images/ui/project-covers/tempcover.jpeg' alt='Project cover' style={imgStyle} />
    </div>    
  )

}

export default TalkingMonster
