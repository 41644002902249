import React from 'react'
import { Item } from 'semantic-ui-react'
import ProjectCover from 'Components/Projects/ProjectCover'

const ProjectSummary = (props) => {
  
  /* --------BEGIN STYLES--------*/
  const imgStyle = {
    width: '120px',
  }
  const titleStyle = {
    marginTop: '50px',
  }
  const bgStyle = {
    backgroundImage: 'url(/images/ui/project-summary-background.png)',
    backgroundSize: '339px 162px',
    backgroundRepeat: 'repeat-x',
  }
  /* --------END STYLES--------*/

  return (    
    <Item.Group unstackable>
      <Item style={bgStyle}>
        <Item.Image verticalAlign='middle' style={imgStyle} >
          <ProjectCover />
        </Item.Image>
        <Item.Content verticalAlign='top' style={titleStyle}>
          <Item.Header as='a'>My Novel with a really long title, okay?</Item.Header>
          <Item.Extra>By {process.env.REACT_APP_TEST_VAR}</Item.Extra>
        </Item.Content>
      </Item>
    </Item.Group>
  )

}

export default ProjectSummary
