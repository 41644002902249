import React from 'react'
import './Styles/App.css';
import Layout from 'Components/Layout/Layout';
import 'semantic-ui-css/semantic.min.css'

function App() {
  return (
    <div data-reactroot>
      <Layout />
    </div>
  );
}

export default App;
