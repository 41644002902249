import React from 'react'
import { Card } from 'semantic-ui-react'
import BackUpPrompt from 'Components/BackUpPrompt/BackUpPrompt';

const BackUpPromptCard = (props) => {

  // Specifies the color of the top bar
  const headerClass = "header-" + props.headerColor;
  // Specifies the text of the top bar
  const headerText = props.headerText;
  
  return (    
    <Card fluid className={headerClass}>
      <Card.Content className='card-header' header={headerText} />
      <Card.Content style={{ paddingLeft: '0px', }}>
        <BackUpPrompt />
      </Card.Content>
    </Card>    
  )

}

export default BackUpPromptCard
