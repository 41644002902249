import React from 'react'
import { Image, Item } from 'semantic-ui-react'

const GroupsItem = (props) => {
  
  /* --------BEGIN STYLES--------*/
  const itemStyle =  {
      padding: "1.5em",
  }
  /* --------END STYLES--------*/
  
  return (
    <Item style={itemStyle}>
      <Item.Image size='small' src='https://react.semantic-ui.com/images/wireframe/image.png' />
      <Item.Content>
        <Item.Header as='a'>{props.groupName}</Item.Header>
        <Item.Meta>{props.schoolName}</Item.Meta>
        <Item.Description>
          <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        </Item.Description>
        <Item.Extra>Additional Details</Item.Extra>
      </Item.Content>
    </Item>
  )
  
}

export default GroupsItem