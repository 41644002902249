import React, { useState } from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Container } from 'semantic-ui-react'
import Home from 'Components/Pages/Home';
import Dashboard from 'Components/Pages/Dashboard';
import WritingSpace from 'Components/Pages/WritingSpace';
import NavBar from 'Components/Layout/NavBar';
import ThemeBar from 'Components/Layout/ThemeBar';
import Footer from 'Components/Layout/Footer';

const Layout = () => {
  
  /* --------BEGIN STYLES--------*/
  const bodyStyle = {
    backgroundColor: '#dddddd',
  }
  const contentStyle = {
    marginTop: '2em',
    minHeight: '800px'
  }
  /* --------END STYLES--------*/
    
  return (    
    <BrowserRouter>
      <div style={bodyStyle}>
        <NavBar />
        <Routes>
          <Route element={<ThemeBar />}>
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/novels/test" element={<WritingSpace />} />
          </Route>
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>  
  )
}

export default Layout