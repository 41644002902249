import React from 'react'
import { Grid, Card, Container } from 'semantic-ui-react'
import ResourcesCard from 'Components/Resources/ResourcesCard';
import NanoFeedCard from 'Components/NanoFeed/NanoFeedCard';
import DareMachineCard from 'Components/DareMachine/DareMachineCard';
import ProjectCard from 'Components/Projects/ProjectCard';
import ChallengeYourself from 'Components/Projects/ChallengeYourself';
import BackUpPromptCard from 'Components/BackUpPrompt/BackUpPromptCard';
import ClassroomsCard from 'Components/Groups/ClassroomsCard';
import MamCard from 'Components/Groups/ClassroomsCard';

const Dashboard = () => {
  
  /* --------BEGIN STYLES--------*/
  const contentStyle = {
    marginTop: '2em',
    minHeight: '800px'
  }
  /* --------END STYLES--------*/
  
  return (
    <Container style={contentStyle}>
      <Grid centered stackable>
        <Grid.Column width={6} >
          <Card.Group centered>
            <ProjectCard
              headerColor="blue"
              headerText="Latest Challenge"
            />
            <ChallengeYourself
              headerColor="blue"
              headerText="Challenge Yourself"
            />
            <DareMachineCard
              headerColor="blue"
              headerText="Dare Machine"
            />
            <BackUpPromptCard
              headerColor="blue"
              headerText="Back Up Those Novels!"
            />
          </Card.Group>
        </Grid.Column>
        <Grid.Column width={10} >
          <Card.Group>
            <NanoFeedCard
              headerColor="orange"
              headerText="NaNo Feed"
            />
            <ClassroomsCard
              headerColor="aqua"
              headerText="Classrooms"
            />
            <MamCard
              headerColor="orange"
              headerText="Contact Support"
            />
            <ResourcesCard
              headerColor="aqua"
              headerText="Educator Resources"
            />
          </Card.Group>
        </Grid.Column>
      </Grid>
    </Container>
  )
  
}

export default Dashboard
