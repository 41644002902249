import React from 'react'
import { Image } from 'semantic-ui-react'

const TalkingMonster = () => {

  /* --------BEGIN STYLES--------*/
  const divStyle = {
    marginLeft: '0px',
    marginRight: '0px',
    width: '132px',
    flexBasis: '132px',
    flexGrow: '0',
    flexShrink: '0',
  }
  const imgStyle = {
    width: '132px',
    height: '250px',
  }
  /* --------END STYLES--------*/
  
  // The three images available for display
  const imageUrls = ['/images/ui/talking-monsters/space-alien.png','/images/ui/talking-monsters/viking.png','/images/ui/talking-monsters/dragon.png']
  
  // The three alt tags
  const imageAlts = ['A space alien in a flying saucer says:','A red-bearded viking leans in to say:','A fire-breathing dragon with a laptop says:']
  
  // The random number
  const imageNumber = Math.floor(Math.random() * 3);

  return (    
    <div style={divStyle}>
      <Image src={imageUrls[imageNumber]} alt={imageAlts[imageNumber]} style={imgStyle} />
    </div>    
  )

}

export default TalkingMonster
