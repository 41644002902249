import React from 'react'
import { Card, Item } from 'semantic-ui-react'
import WordSprint from 'Components/WordSprints/WordSprint'
import DareMachine from 'Components/DareMachine/DareMachine'
import ProjectDownload from 'Components/ProjectDownload/ProjectDownload'

const WritingToolsCard = (props) => {

  // Specifies the color of the top bar
  const headerClass = "header-" + props.headerColor;
  // Specifies the text of the top bar
  const headerText = props.headerText;
  
  return (
    
    <Card fluid className={headerClass}>
      <Card.Content className='card-header' header={headerText} />
      <Card.Content>
        <Item.Group divided unstackable>
          <WordSprint />
          <DareMachine />
          <ProjectDownload />
        </Item.Group>
      </Card.Content>
    </Card>

  )

}

export default WritingToolsCard
