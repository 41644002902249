import React from 'react'
import { Container, Button } from 'semantic-ui-react'

const Home = () => {
  
  /* --------BEGIN STYLES--------*/
  const heroStyle = {
    maxWidth: '1640px',
    margin: '0 auto',
    background: '#FFFFFF url(/images/ui/hero.png) top center no-repeat',
    height: '818px',
    paddingTop: '309px',
  }
  const centeredStyle = {
    width: '100%',
    textAlign: 'center',
    margin: '0 auto',
  }
  const h1Style = {
    textIndent: '-999999px',
    background: 'url(/images/ui/yourstory-text.png) top center no-repeat',
    height: '98px',
  }
  const h2Style = {
    color: '#2C3537',
    fontSize: '24px',
    marginTop: '-14px',
    marginBottom: '.8em',
  }
  const buttonStyle = {
    background: '#EFA615',
    color: '#ffffff',
    border: '3px solid #ffffff',
    fontSize: '1.25em',
    padding: '0.35em 2.3em',
    marginTop: '0px',
    marginBottom: '3px',
    transition: '0.25s',
    borderRadius: '5px',
  }
  const tinyStyle = {
    fontSize: '0.9em',
  } 
  /* --------END STYLES--------*/
  
  
  return (
    <Container fluid style={heroStyle}>
      <h1 style={{...centeredStyle, ...h1Style}}>Your story matters</h1>
      <h2 style={{...centeredStyle, ...h2Style}}>WRITE YOUR NOVEL NOW.</h2>
      <div style={centeredStyle} >
        <Button style={buttonStyle} >SIGN UP</Button>
      </div>
      <div style={{...centeredStyle, ...tinyStyle}}>Free, now and forever.</div>
    </Container>
  )
  
}

export default Home
