import React from 'react'
import { Card, Icon, Grid } from 'semantic-ui-react'

const ResourcesCard = (props) => {

  // Specifies the color of the top bar
  const headerClass = "header-" + props.headerColor;
  // Specifies the text of the top bar
  const headerText = props.headerText;
  
  /* --------BEGIN STYLES--------*/
  const headerStyle =  {
    padding: "1em 1em 0.5em 1em",
    borderBottom: "3px rgba(0,0,0,0.15) solid",
  }  
  const itemStyle = {
    display: 'flex',
    justifyContent: 'left',
    verticalAlign: 'center',
    marginLeft: '20%',
    padding: '10px',
  }
  const textStyle = {
    alignSelf: 'center',
    fontSize: '1.5em',
    marginLeft: '10px',
  }
  /* --------END STYLES--------*/
  
  return (
    <Card fluid className={headerClass}>
      <Card.Content className='card-header' header={headerText} style={headerStyle} />
      <Card.Content>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <a style={itemStyle} href='https://ywp.zendesk.com/hc/en-us' target='_blank' rel="noopener noreferrer" >
                <Icon size='big' style={{color: '#09C1E6'}} name='help' />
                <div style={textStyle}>Help & FAQ</div>
              </a>
            </Grid.Column>
            <Grid.Column>
              <a style={itemStyle} href='/pages/teaching-resources#lessons' target='_blank' rel="noopener noreferrer" >
                <Icon size='big' style={{color: '#0dcec2'}} name='map' />
                <div style={textStyle}>Lesson Plans</div>
              </a>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <a style={itemStyle} href='/pages/teaching-resources#workbooks' target='_blank' rel="noopener noreferrer" >
                <Icon size='big' style={{color: '#faad26'}} name='pencil' />
                <div style={textStyle}>Workbooks</div>
              </a>
            </Grid.Column>
            <Grid.Column>
              <a style={itemStyle} href='/pages/teaching-resources#kits' target='_blank' rel="noopener noreferrer" >
                <Icon size='big' style={{color: '#8561a8'}} name='suitcase' />
                <div style={textStyle}>Classroom Kits</div>
              </a>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Content>
    </Card>
  )

}

export default ResourcesCard
