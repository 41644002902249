import React from 'react'
import { Card, Icon } from 'semantic-ui-react'
import GroupsItems from 'Components/Groups/GroupsItems'

const ClassroomsCard = (props) => {

  // Specifies the color of the top bar
  const headerClass = "header-" + props.headerColor;
  // Specifies the text of the top bar
  const headerText = props.headerText;
  
  return (    
    <Card fluid className={headerClass}>
      <Card.Content className='card-header' header={headerText} />
      <GroupsItems />
      <Card.Content extra>
        <Icon name='plus' />Create another classroom
      </Card.Content>
    </Card>    
  )
  
}

export default ClassroomsCard
