import React from 'react'
import { Card, Item } from 'semantic-ui-react'
import ChaptersListDraggable from 'Components/ChaptersList/ChaptersListDraggable';
import ChapterAddModal from 'Components/ChaptersList/ChapterAddModal';

const ChaptersListCard = (props) => {

  // Specifies the color of the top bar
  const headerClass = "header-" + props.headerColor;
  // Specifies the text of the top bar
  const headerText = props.headerText;
  
  const scrollStyle = {
    maxHeight: '300px',
    overflow: 'auto',
  }
  
  return (    
    <Card fluid className={headerClass}>
      <Card.Content className='card-header' header={headerText} />
      <Card.Content>
        <Item.Group style={scrollStyle} >
          <ChaptersListDraggable />
        </Item.Group>
      </Card.Content>
      <Card.Content extra>
        <ChapterAddModal />
      </Card.Content>
    </Card>    
  )

}

export default ChaptersListCard
