import React from 'react'
import { Item } from 'semantic-ui-react'
import TalkingMonster from 'Components/TalkingMonsters/TalkingMonster';
import QuoteBubble from 'Components/TalkingMonsters/QuoteBubble';

const BackUpPrompt = (props) => {

  return (    
    <Item>
      <div style={{ display: 'flex' }}>
        <TalkingMonster />
        <div>
          <QuoteBubble
            bubbleText = "Save all your projects in at least two places, not just here on the YWP site!"
          />
        </div>
      </div>
    </Item>
  )

}

export default BackUpPrompt
