import React from 'react'
import { Link } from "react-router-dom";
import {
  Container,
  Dropdown,
  Menu,
} from 'semantic-ui-react'

const NavBar = () => {
  
  /* --------BEGIN STYLES--------*/
  const menuStyle = {
    margin: '0 0',
    borderRadius: '0',
  }
  const logoItemStyle = {
    width: '177px',
    height: '40px',
    overflow: 'visible',
    display: 'block',
  }
  const logoStyle = {
    width: '177px',
    height: '109px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '167px 109px',
    backgroundImage: 'url("/images/ui/logos/logo-short.png")',
  }
  /* --------END STYLES--------*/
  
  return (
    <Menu inverted style={menuStyle}>
      <Container>
        <Menu.Item as='a' header style={logoItemStyle}>
          <div style={logoStyle} />
        </Menu.Item>    
        <Menu.Item as={Link} to='/dashboard'>My Dashboard</Menu.Item>
        <Dropdown item simple text='My Novels'>
          <Dropdown.Menu>
            <Dropdown.Item>View All Novels</Dropdown.Item>
            <Dropdown.Item as={Link} to='/novels/test'>Test</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown item simple text='My Groups'>
          <Dropdown.Menu>
            <Dropdown.Item>View All Classrooms</Dropdown.Item>
            <Dropdown.Item>Ms. Baker Classroom</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item as='a'>Forums</Menu.Item>
        <Dropdown item simple text='Resources'>
          <Dropdown.Menu>
            <Dropdown.Item>About</Dropdown.Item>
            <Dropdown.Item>How It Works</Dropdown.Item>
            <Dropdown.Item>Getting Started</Dropdown.Item>
            <Dropdown.Item>Teaching Resources</Dropdown.Item>
            <Dropdown.Item>NaNo Writing Hub</Dropdown.Item>
            <Dropdown.Item>Breaking News</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Container>
    </Menu>
  )
}

export default NavBar