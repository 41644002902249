import update from 'immutability-helper'
import { useCallback, useState } from 'react'
import ChapterDraggable from 'Components/ChaptersList/ChapterDraggable';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

const ChaptersListDraggable = () => {
  
  const style = {
    width: '100%',
  }
  
  {
    const [cards, setCards] = useState([
      {
        id: 1,
        text: 'Chapter 1',
      },
      {
        id: 2,
        text: 'Chapter 2',
      },
      {
        id: 3,
        text: 'Chapter 3',
      },
      {
        id: 4,
        text: 'Chapter 4',
      },
      {
        id: 5,
        text: 'Chapter 5',
      },
      {
        id: 6,
        text: 'Chapter 6',
      },
      {
        id: 7,
        text: 'Chapter 7',
      },
      {
        id: 8,
        text: 'Chapter 8',
      },
      {
        id: 9,
        text: 'Chapter 9',
      },
      {
        id: 10,
        text: 'Chapter 10',
      },
    ])
    const moveCard = useCallback((dragIndex, hoverIndex) => {
      setCards((prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        }),
      )
    }, [])
    const renderCard = useCallback((card, index) => {
      return (
        <ChapterDraggable
          key={card.id}
          index={index}
          id={card.id}
          text={card.text}
          moveCard={moveCard}
        />
      )
    }, [])
    return (
      <>
        <DndProvider backend={HTML5Backend}><div style={style} >{cards.map((card, i) => renderCard(card, i))}</div></DndProvider>
      </>
    )
  }
}

export default ChaptersListDraggable