import React, { contextRef } from 'react'
import { Grid, Card, Sticky, Rail, Ref, Segment, Container } from 'semantic-ui-react'
import ProjectCard from 'Components/Projects/ProjectCard';
import WritingSheetCard from 'Components/Projects/WritingSheetCard';
import WritingToolsCard from 'Components/Projects/WritingToolsCard';
import BackUpPromptCard from 'Components/BackUpPrompt/BackUpPromptCard';
import ChaptersListCard from 'Components/ChaptersList/ChaptersListCard';

const WritingSpace = () => {
  
  /* --------BEGIN STYLES--------*/
  const contentStyle = {
    marginTop: '2em',
    minHeight: '800px'
  }
  const cardStyle = {
    position: '-webkit-sticky',
    position: 'sticky',
    top: '0',
  }
  const titleStyle = {
    color: '#000000',
  }
  const writingSpaceStyle = {
    height: 'calc(100vh - 150px)',
  }
  /* --------END STYLES--------*/
  //const handleContextRef = contextRef => this.setState({ contextRef })
  
  return (
    <Container style={contentStyle}>
      <Grid centered stackable>
        <Grid.Column width={6} >
          <Card.Group centered>
            <ProjectCard
              headerColor="blue"
              headerText="Latest Challenge"
            />
            <ChaptersListCard
              headerColor="blue"
              headerText="Chapters and Notes"
            />
            <WritingToolsCard
              headerColor="blue"
              headerText="Writing Tools"
            />
            <BackUpPromptCard
              headerColor="blue"
              headerText="Back Up This Project!"
            />
          </Card.Group>
        </Grid.Column>
        <Grid.Column width={10} style={cardStyle} >
          <Sticky>
            <Card.Group>
              <WritingSheetCard
                headerColor="white"
                headerText="Chapter One"
              />
            </Card.Group>
          </Sticky>
        </Grid.Column>
      </Grid>
    </Container>
  )

}

export default WritingSpace
