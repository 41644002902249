import React from 'react'
import {
  Container,
  Divider,
  Grid,
  List,
  Segment,
  Icon,
} from 'semantic-ui-react'

const Footer = () => {
  
  /* --------BEGIN STYLES--------*/
  const segmentStyle = {
    margin: '5em 0em 0em',
    padding: '5em 0em',
  }
  /* --------END STYLES--------*/
  
  return (
    <Segment inverted vertical style={segmentStyle}>
      <Container textAlign='center'>
        <Grid divided inverted stackable>
          <Grid.Column width={3}>
            <List link inverted>
              <List.Item as='a'>About</List.Item>
              <List.Item as='a'>Help & FAQ</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={3}>
            <List link inverted>
              <List.Item as='a'>Contact Us</List.Item>
              <List.Item as='a'>Terms & Conditions</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={3}>
            <List link inverted>
              <List.Item as='a'>Privacy Policy</List.Item>
              <List.Item as='a'>Codes of Conduct</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={7}>
            <Icon name='facebook' size='big' />
            <Icon name='instagram' size='big' />
            <Icon name='youtube' size='big' />
            <Icon name='twitter' size='big' />
            <Icon name='tumblr' size='big' />
          </Grid.Column>
        </Grid>

        <Divider inverted section />
        <List horizontal inverted divided link size='small'>
          <List.Item as='a' href='#'>
            A PROGRAM OF NATIONAL NOVEL WRITING MONTH, A 501(C)(3) NONPROFIT THAT BELIEVES YOUR STORY MATTERS.
          </List.Item>
        </List>
      </Container>
    </Segment>
  )
}

export default Footer