import React from 'react'
import { Image } from 'semantic-ui-react'

const QuoteBubble = (props) => {

  // The tesxt content of the bubble
  const bubbleText = props.bubbleText;

  /* --------BEGIN STYLES--------*/
  const QuoteBubbleStyle = {
    position: 'relative',
    textAlign: 'center',
    width: '70%',
    padding: '20px',
    marginLeft: '31px',
    marginBottom: '20px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 5px #888888',
    fontSize: '13p',
    float: 'left',
    borderRadius: '20px 20px 20px 0px',
  }  
  const QuoteArrowStyle = {
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    marginLeft: '-31px',
    marginBottom: '-4px',
  }
  /* --------END STYLES--------*/
  
  return (    
    <div style={QuoteBubbleStyle} >
      {bubbleText}
      <div style={QuoteArrowStyle}>
        <Image src="/images/ui/quote-left.png" />
      </div>
    </div>    
  )

}

export default QuoteBubble
