import React from 'react'
import { Item } from 'semantic-ui-react'

const NanoFeed = (props) => {
  
  return (    
    <Item>
      <Item.Image size='tiny' src='/images/ui/small-rocket.png' alt='' />
      <Item.Content verticalAlign='middle'>
        <Item.Header as='a'>Submit an excerpt to our 2023 "Now What?" contest!</Item.Header>
        <Item.Description>
          Breaking News: Submit a revised excerpt from your novel (up to 400 words) for the chance to win. The deadline to submit is February 28.
        </Item.Description>
      </Item.Content>
    </Item>    
  )

}

export default NanoFeed
