import React from 'react'
import { Card } from 'semantic-ui-react'

const WritingSheetCard = (props) => {

  // Specifies the color of the top bar
  const headerClass = "header-" + props.headerColor;
  // Specifies the text of the top bar
  const headerText = props.headerText;

  /* --------BEGIN STYLES--------*/
  const cardStyle = {
    position: '-webkit-sticky',
    position: 'sticky',
    top: '0',
  }
  const titleStyle = {
    color: '#000000',
  }
  const writingSpaceStyle = {
    height: 'calc(100vh - 150px)',
  }
  /* --------END STYLES--------*/

  return (  
    <Card fluid className={headerClass} style={cardStyle}>
      <Card.Content className='card-header' style={titleStyle} header={headerText} />
      <Card.Content style={writingSpaceStyle}>
        Lorem ipsum
      </Card.Content>
    </Card>    
  )

}

export default WritingSheetCard
