import React from 'react'
import { Item } from 'semantic-ui-react'
import GroupsItem from 'Components/Groups/GroupsItem'

const GroupsItems = () => {
  
  return (
    <Item.Group divided>
      <GroupsItem groupName='Classroom of Dave' schoolName='El Cerrito High School' />
      <GroupsItem groupName='Classroom of Bill' schoolName='' />
      <GroupsItem groupName='Classroom of Wren' schoolName='Berkeley High School' />
    </Item.Group>
  )
}

export default GroupsItems