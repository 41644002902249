import React from 'react'
import { Outlet } from 'react-router';
import {
  Container,
} from 'semantic-ui-react'

const ThemeBar = () => {
  
  /* --------START DESCRIPTION--------
  ThemeBar appears on every page EXCEPT the logged out home page.
  Outlet renders the rest of the embedded route, i.e. the specified page.
  -----------END DESCRIPTION-------- */
  
  
  /* --------BEGIN STYLES--------*/
  const themeBarStyle = {
    height: '98px',
    backgroundImage: 'url("/images/ui/themes/forest-bar.png")',
  }
  /* --------END STYLES--------*/
  
  return (
    <>
      <Container fluid style={themeBarStyle} />
      <Outlet />
    </>
  )
}

export default ThemeBar