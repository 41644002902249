import React from 'react'
import { Card, Button } from 'semantic-ui-react'
import QuoteBubble from 'Components/TalkingMonsters/QuoteBubble';
import TalkingMonster from 'Components/TalkingMonsters/TalkingMonster';

const ChallengeYourself = (props) => {

  // Specifies the color of the top bar
  const headerClass = "header-" + props.headerColor;
  // Specifies the text of the top bar
  const headerText = props.headerText;
  
  /* --------BEGIN STYLES--------*/
  const buttonDivStyle = {
    paddingLeft: '31px',
  }
  const topButtonStyle = {
    marginBottom: '10px',
  }
  /* --------END STYLES--------*/

  return (    
    <Card fluid className={headerClass}>
      <Card.Content className='card-header' header={headerText} />
      <Card.Content style={{ paddingLeft: '0px', }}>
        <div style={{ display: 'flex' }}>
          <TalkingMonster />
          <div>
            <QuoteBubble
              bubbleText = "You've got so much to say! Would you like to set another word-count challenge now?"
            />
            <div style={buttonDivStyle}>
              <Button style={topButtonStyle}>Yes, start a new novel for it!</Button>
              <Button>Yes, continue working on a novel I've already started!</Button>
            </div>
          </div>
        </div>
      </Card.Content>
    </Card>
    
  )

}

export default ChallengeYourself
