import React from 'react'
import { Button, Item } from 'semantic-ui-react'

const WordSprint = (props) => {
  
  return (
    <Item>
      <Item.Image size='tiny' src='/images/ui/word-sprint-pencil.png' alt='' />
      <Item.Content verticalAlign='middle'>
        <Item.Header as='a'>Word Sprint</Item.Header>
        <Item.Description>
          Your last word sprint was <b>5 minutes</b>. You wrote <b>0 words</b> of your <b>100-word goal</b>.
        </Item.Description>
        <Item.Extra><Button>Sprint Again</Button></Item.Extra>
      </Item.Content>
    </Item>
  )

}

export default WordSprint
